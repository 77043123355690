.section {
    background: rgb(var(--bright));
    padding: 8rem 0 8rem 0;
}

.section__container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


/* section CONTACTS */

.contacts {
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 600px;
}

.contacts__container {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem 4rem 1rem;
}

.contacts__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    text-decoration: none;
}

.contacts .contacts__item:nth-child(1) {
    animation: shiftFromLeft .8s 0s ease-out both;
}

.contacts .contacts__item:nth-child(2) {
    animation: shiftFromLeft .8s .25s ease-out both;
}

.contacts .contacts__item:nth-child(3) {
    animation: shiftFromLeft .8s .5s ease-out both;
}

.contacts .contacts__item:nth-child(4) {
    animation: shiftFromLeft .8s .75s ease-out both;
}

.contacts .contacts__item:nth-child(5) {
    animation: shiftFromLeft .8s 1s ease-out both;
}

.contacts .contacts__item:nth-child(6) {
    animation: shiftFromLeft .8s 1.25s ease-out both;
}

.contacts .contacts__item:nth-child(7) {
    animation: shiftFromLeft .8s 1.5s ease-out both;
}

@keyframes shiftFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-30%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.svg {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: rgb(var(--accent));
}

.svg:active {
    transform: scale(1.1);
}

.contacts__desc {
    width: 100%;
    padding: 1rem;
    color: black;
    font-size: 1rem;
    text-align: center;
    opacity: 0.8;
}



.contact__figure {
    padding: 0 1rem;
}

.contact__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}



@media (min-width: 500px) {

    .contacts__item {
        flex-direction: row;
    }

    .contacts__desc {
        text-align: start;
    }

    .svg {
        height: 3rem;
        width: 3rem;
    }

    .contacts__desc {
        font-size: 1.5rem;
    }
}

@media (min-width: 1000px) {

    .section__container {
        flex-direction: row;
    }

    .contacts {
        align-self: flex-start;
    }
}