.ad {
    padding: 2rem 1rem;
    max-width: 1200px;
    margin: auto;
    position: relative;
}

.ad__section {
    padding: 2rem 0;
    margin: 0 0 2rem 0;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.ad__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem 2rem 0 2rem;
}
.ad__containerPhotos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1rem;
}

.ad__itemContainer {
    min-width: 12rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}

.ad__itemContainerWide {
    width: 100%;
    padding: 2rem;
}

.ad__itemDesc {
    padding-bottom: 0.5rem;
    font-size: 1rem;
}
.ad__itemList {
    background-color: white;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 0.1rem solid rgba(var(--accent), 0.3);
    /* box-shadow: 0 0 10px rgba(var(--accent), 0.3); */
    outline: none;
}
.ad__itemLabel {
    height: 9rem;
    width: 12rem;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad__itemImage {
    max-height: 100%;
    max-width: 100%;
}

.ad__progressContainer {
    padding: 0.5rem;
    background: rgba(196, 196, 196, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.ad__progressBar {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 1rem;
}
.ad__itemFirstPhotDesc {
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    right: 1rem;
    text-align: center;
    font-size: 1rem;
}
.btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

.btn {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    outline: none;
    border: 0.1rem solid rgba(var(--accent), 1);
    background: white;
    box-shadow: 0 0 10px rgba(var(--accent), 0.5);
    cursor: pointer;
}

.btnMmargin {
    margin: 1rem;
}

.btn:hover {
    box-shadow: 0 0 20px rgba(var(--accent), 0.5);
}

.btn:active {
    box-shadow: none;
    background: rgba(var(--accent), 1);
    color: white;
}