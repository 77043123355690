* {
    /* add color in index.css eg: --accent: 0, 200, 0;*/
    --colorAlertPrivacy: var(--accent);
}

.container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 900;
    display: flex;
    align-items: center;
    padding: 1rem;
    animation: showPrivacy 0.5s;
}

@keyframes showPrivacy {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

.button {
    font-size: 1rem;
    padding: 0.5rem 2rem;
    border: rgb(var(--colorAlertPrivacy)) 0.1rem solid;
    background-color: transparent;
    color: white;
    outline: none;
    cursor: pointer;
}

.button:hover {
    box-shadow: 0 0 20px rgb(var(--colorAlertPrivacy));
}

.button:active {
    background-color: rgba(var(--colorAlertPrivacy, 0.8));
}

.content {
    font-size: 1rem;
    color: white;
    padding: 0.3rem;

}

.text {
    font-size: 1rem;
    color: white;
}

.anchor {
    color: white;
    opacity: 0.6;
    cursor: pointer;
}

.anchor:hover {
    opacity: 0.8;
}

.anchor:active {
    opacity: 1;
}