/* ALL ADS */

.ads {
    padding: 2rem 0.5rem;
    margin: 0 0.5rem;
}

.ads__item {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0;
    text-decoration: none;
}

.ads__itemContainer {
    display: flex;
    overflow: hidden;
}

.ads__itemFigure {
    width: 10rem;
    height: 7.5rem;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ads__itemImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ads__itemDescContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.ads__itemDescTop {
    display: flex;
    font-size: 1rem;
}


.ads__itemDescBottom {
    display: flex;
    font-size: 1rem;
}

.ads__itemDescRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 0.8rem;
    font-weight: bold;
    align-self: flex-start;
    white-space: nowrap;
    
}

.ads__itemText {
    font-size: inherit;
    padding: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(var(--dark));
}

@media (min-width: 500px) {

}

@media (min-width: 800px) {
    .ads__itemFigure {
        width: 20rem;
        height: 15rem;
    }
    .ads__itemDescTop {
        font-size: 1.5rem;
    }
    .ads__itemDescRight {
        font-size: 1rem;
    }
    
}