.background {
    background: rgb(var(--dark));
}

.desc {
    max-width: 1200px;
    margin: auto;
    padding: 0 1rem 4rem 1rem;
}

.desc_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.desc__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    padding-top: 4rem;
}

.desc__title {
    font-size: 1.2rem;
    color: rgb(var(--accent));
    letter-spacing: 2px;
    font-family: 'Kalam', cursive;
}

.desc__text {
    font-size: 1rem;
    color: white;
    padding: 2rem 0 0 0;
    line-height: 2rem;
}

.desc__nav {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.desc__link {
    color: white;
    text-decoration: none;
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.desc__arrow {
    margin-right: 1rem;
    content: "";
    display: inline-block;
    padding: 0.1rem;
    border: rgb(var(--accent)) solid;
    clip-path: circle(50%);
}

.desc__contact {
    display: flex;
    align-items: center;
    padding-top: 2rem;
}

.desc__contactItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
    position: relative;
    text-decoration: none;
}

.desc__contactItemSvg {
    height: 1.5rem;
    width: 1.5rem;
    fill: white;
}

.desc__contactItemSvg:active {
    transform: scale(1.5);
}

.desc__contactItemText {
    color: white;
    font-size: 0.5rem;
    transition: 0.3s ease-in-out;
    opacity: 0;
}

.desc__contactItemSvg:hover+.desc__contactItemText {
    transform: scale(2) translateY(150%);
    opacity: 1;
}

.foot {
    background: rgba(var(--bright), 0.5);
    padding-bottom: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.foot__item {
    color: white;
    font-size: 1rem;
    padding: 1rem 1rem 0 1rem;
    text-decoration: none;
}


@media (min-width:800px) {

    .desc_container {
        flex-direction: row;
    }

    .foot {
        flex-direction: row;
    }
}