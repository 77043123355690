.background {
    background: white;
    color: black;
}

.container {
    padding: 4rem 0 0 0;
    max-width: 1200px;
    margin: auto;
}

.item {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: 4rem 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out;
}

.item__header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item:hover {
    transform: scale(1.02);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.item__figure {
    width: 8rem;
    height: 8rem;
    position: relative;
    clip-path: circle(50%);
    margin: 1rem
}

.item__headerDesc {
    padding: 1rem;
}

.item__ul {
    padding: 1rem;
    list-style-image: url("../../assets/check.png")
}

.item__ul2 {
    padding: 0 2rem;
    list-style-image: url("../../assets/check.png")
}

.item__li {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 0;
    color: black;
    font-weight: normal;
}


@media (min-width: 500px) {
    .item__figure {
        width: 12rem;
        height: 12rem;
    }

    .item__ul {
        padding: 2rem;
    }
}

@media (min-width: 700px) {
    .item__header {
        flex-direction: row;
    }
}