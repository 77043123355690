.background {
    background: white;
    color: black;
    padding: 4rem 0 0 0;
}


/*section START*/

.start {
    background: rgb(var(--dark));
}

.start__container {
    max-width: 1920px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.start__figure {
    flex: 1;
}

.start__line {
    padding: 0.5rem;
    background: rgb(var(--accent));
}

.start__img {
    width: 100%;
    height: 100%;
    display: block;
}

.start__containerText {
    background: rgba(30, 30, 30, 0.9);
    background: rgb(var(--accent));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 1rem;
    border-radius: 999px;
}

.start__text {
    color: white;
    font-weight: 600;
    font-family: 'Kalam', cursive;
    font-size: 1.5rem;
}

.start__textMiddle {
    color: black;

    /* text animation */
    overflow: hidden;
    white-space: nowrap;
    margin: 0 auto;
    animation: typing 1.5s steps(18, end);
}

/* The typing effect */
@keyframes typing {
    0% {
        width: 0
    }

    100% {
        width: 100%
    }
}



/* section APPROACH */

.approach {
    background: white;
}

.approach__textContainer {
    margin: auto;
    max-width: 1200px;
    width: 100%;
    padding: 4rem 2rem 4rem 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}

.approach__item {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    background: linear-gradient(to right bottom, rgba(var(--accent), 0.8), rgba(255, 255, 255, 0.1));
    border: 0.1rem rgb(var(--dark)) solid;
}

.approach__h2 {
    font-size: 1.5rem;
    padding: 1rem 0;
}

.approach__p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.5rem;
}


/*section ABOUT*/

.about {
    background: #6e6e6e;/*rgb(var(--dark));*/

    max-width: 1920px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about__text {
    flex: 1;
    padding: 2rem 1rem;
}

.about__textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-right: auto;
}

.about__figure {
    flex: 1;
}

.about__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

/*section OFFER*/

.offer {
    background: white;

}

.offer__container {
    max-width: 1920px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.offer__text {
    flex: 1;
    padding: 2rem 1rem;
}

.offer__textContainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-left: auto;
}

.offer__figure {
    flex: 1;
}

.offer__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}


/*section WHY*/

.why {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5)100%), url(../../assets/home_why_background.jpg) center center no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

@supports (-webkit-overflow-scrolling: touch) {
    .why {
        background-attachment: scroll;
    }
}

.why__container {
    max-width: 1200px;
    margin: auto;
    padding: 2rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
}

.why__textContainer {
    background: rgba(255, 255, 255, 0.9);
    max-width: 600px;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
}

.why__figure1 {
    max-width: 300px;
    transform: translateY(2rem);
}

.why__figure2 {
    max-width: 300px;
    transform: translateY(-2rem);
    justify-self: end;
}

.why__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.why__ul {
    padding: 1rem;
    list-style-image: url("../../assets/check.png")
}

.why__li {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 0;
    color: black;
    font-weight: normal;
}



/*section CONTACT*/

.contact {
    background: white;
    max-width: 1200px;
    margin: auto;
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact__text {
    max-width: 600px;
    flex: 1;
    padding-bottom: 4rem;
}

.contact__textContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.contact__tel {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    color: black;
    font-weight: 300;
    padding: 1rem 0 0 0;
}

.contact__telIcon {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: rgb(var(--accent));

    /* animation */
    animation: shake 2s cubic-bezier(.36, .07, .19, .97) infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {

    2%,
    98% {
        transform: translate3d(-1px, 0, 0);
    }

    4%,
    96% {
        transform: translate3d(2px, 0, 0);
    }

    6%,
    94% {
        transform: translate3d(-4px, 0, 0);
    }

    8%,
    92% {
        transform: translate3d(4px, 0, 0);
    }
}

.contact__figure {
    flex: 1;
    padding: 0 1rem;

}

.contact__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}


/* MEDIA */

@media (min-width: 500px) {

    .start__text {
        font-size: 2rem;
        padding: 0.5rem;
    }

    .why__container {
        padding: 0 1rem;
    }

    .why__textContainer {
        padding: 2rem;
    }

    .why__figure1 {
        max-width: 500px;
        transform: translateY(4rem);

    }

    .why__figure2 {
        max-width: 500px;
        transform: translateY(-4rem);
    }
}

@media (min-width: 600px) {

    .approach__textContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1000px) {

    .start__container {
        flex-direction: row;
    }

    .start__text {
        font-size: 2rem;
        padding: 0.5rem;
    }

    .approach__textContainer {
        grid-template-columns: repeat(4, 1fr);
    }

    .about {
        flex-direction: row;
    }

    .about__figure {
        clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);

    }

    .offer__container {
        flex-direction: row;
    }

    .offer__figure {
        clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    }

    .etraining__container {
        flex-direction: row;
    }

    .etraining__text {
        padding: 0 1rem;
    }

    .knowledge {
        flex-direction: row;
    }

    .contact {
        flex-direction: row;
    }

    .contact__tel {
        font-size: 3rem;
    }
}