.section {
    background: var(--backgroundCustom);
    padding: 4rem 0 0 0;
}

.section__container {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}


/* CATEGORIES */

.categories {
    width: 100%;
    padding: 0 0.5rem;
    margin: 0 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.categories__mainContainer {
    margin-top: 2rem;
}

.categories__container {
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
}

.categories__itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.2rem;
    margin: 0.5rem;
    cursor: pointer;
    box-shadow: 0 0 0 rgb(var(--accent));
    position: relative;
}

.categories__itemContainerActive {
    box-shadow: 0 0 20px rgb(var(--accent));
    border: 1px solid rgb(var(--accent));
}

.categories__itemFigure {
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: circle(50%);
}

.categories__itemSVG {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categories__itemDesc {
    padding-top: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
}

.categories__underLine {
    margin: 0.5rem auto 0 auto;
    width: 18rem;
    height: 0.2rem;
    background: transparent;
}

.categories__underLineAccent {
    background: rgb(var(--accent));
}

/* ADS */

.ads {
    width: 100%;
    margin: 0 1rem;
    padding: 2rem 0 0 0;
    position: relative;
}

.ads__emptyContainer {
    max-width: 600px;
    margin: auto;
    display: flex;
    padding: 2rem 0;
}

.ads__emptyContainerDesc {
    width: 50%;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    padding-bottom: 1rem;
}

.ads__emptySVG {
    width: 100%;
    height: 100%;
}

@media (min-width: 750px) {
    .categories {
        flex-direction: row;
    }
}